<template>
	<div>
		<!--顶部工具条-->
		<el-col :span="24">
			<el-form :inline="true">
				<el-form-item label="关键字:">
					<el-input v-model="filters.searchKey" @input="(e) => (filters.searchKey = utils.validForbid(e))" placeholder="请输入项目编号/项目名称" clearable @clear='clearContent'></el-input>
				</el-form-item>
                <el-form-item >
                    <el-select v-model="filters.areaStr" placeholder="请选择省份" filterable clearable>
                        <el-option
                            v-for="item in areaArr"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item >
                    <el-select v-model="filters.taskStatus" placeholder="指派类型" clearable>
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        clearable>
                        </el-option>
                    </el-select>
                </el-form-item>
				<el-form-item>
					<toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
				</el-form-item>
			</el-form>
		</el-col>
		<!--列表-->
		<el-table
			:data="dataPer"
			highlight-current-row
			@current-change="selectCurrentRow"
			v-loading="listLoading"
			@selection-change="selsChange"
			style="width: 100%;"
            :cell-style="rowClass"
		>
			<!-- <el-table-column type="selection" width="50">
			</el-table-column> -->
			<el-table-column type="index" width="80" align="center" :index='indexMethod'></el-table-column>
			<el-table-column prop="projectNo" label="项目编号" align="center"></el-table-column>
			<el-table-column prop="areaData" label="所属区县" align="center"></el-table-column>
			<el-table-column prop="projectName" label="项目名称" align="center"></el-table-column>
			<el-table-column prop="buildingArea" label="建筑面积" align="center"></el-table-column>
			<el-table-column prop="drawingCount" label="图纸数量" align="center"></el-table-column>
            <el-table-column prop="registerDate" label="项目登记日期" align="center"></el-table-column>
            <el-table-column prop="govRemain" label="政府承诺审结日倒计时（天）" align="center"></el-table-column>
            <el-table-column prop="lawRemain" label="法定审结日倒计时（天）" align="center"></el-table-column>
            <el-table-column prop="aiName" label="AI图审" align="center"></el-table-column>
            <el-table-column prop="localEngineer.engineerName" label="驻场图审工程师" align="center"></el-table-column>
            <el-table-column prop="assistanceEngineer.engineerName" label="省内援助专家" align="center"></el-table-column>
            <el-table-column prop="onlineEngineer1.engineerName" label="在线图审专家1" align="center"></el-table-column>
            <el-table-column prop="onlineEngineer2.engineerName" label="在线图审专家2" align="center"></el-table-column>
		</el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageIndex"
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount"
            >
            </el-pagination>
        </el-col>
        <el-dialog
        title="指派图审人员"
        :visible.sync="assignTaskVisible"
        v-model="assignTaskVisible"
        :close-on-click-modal="false"
        >
            <el-form label-width="150px">
                <el-form-item label="项目名称：">
                    <div>{{selectItem.projectName}}</div>
                </el-form-item>
                <el-form-item label="驻场图审工程师:">
                    <div>{{selectItem.localEngineer.engineerName}}</div>
                </el-form-item>
                <el-form-item label="省内援助专家:">
                    <div v-if="!selectItem.localEngineer.isApplyAssistance">无需分配</div>
                    <div v-else>
                        <div v-if="selectItem.assistanceEngineer.isAssigned">{{selectItem.assistanceEngineer.engineerName}}</div>
                        <el-autocomplete class="expertSelect" v-else v-model="asssignData.assistanceEngineer.engineerName" value-key="userName" @select="handleSelectAsistance" :fetch-suggestions="getYuanzhuExpert">
                            <template #default="{ item }">
                                <span >{{ item.userName }}</span>
                                <span class="link">（{{ item.provinceName }}）</span>
                            </template>
                        </el-autocomplete>
                    </div>
                </el-form-item>
                <el-form-item label="在线图审专家1:">
                    <div v-if="selectItem.onlineEngineer1.isAssigned">{{selectItem.onlineEngineer1.engineerName}}</div>
                    <el-autocomplete class="expertSelect" v-else v-model="asssignData.onlineEngineer1.engineerName" value-key="userName" @select="handleSelectOnline1" :fetch-suggestions="getOnline1Expert">
                        <template #default="{ item }">
                            <span >{{ item.userName }}</span>
                            <span class="link">（{{ item.provinceName }}）</span>
                        </template>
                    </el-autocomplete>
                </el-form-item>
                <el-form-item label="在线图审专家2:">
                    <div v-if="selectItem.onlineEngineer2.isAssigned">{{selectItem.onlineEngineer2.engineerName}}</div>
                    <el-autocomplete class="expertSelect" v-else v-model="asssignData.onlineEngineer2.engineerName" value-key="userName" @select="handleSelectOnline2" :fetch-suggestions="getOnline2Expert">
                        <template #default="{ item }">
                            <span >{{ item.userName }}</span>
                            <span class="link">（{{ item.provinceName }}）</span>
                        </template>
                    </el-autocomplete>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="assignTaskVisible = false">取消</el-button>
                <el-button @click.native="assignTaskConfirm" :loading="assignTaskLoading" type="primary">确认</el-button>
            </div>
        </el-dialog>

	</div>
</template>
<script>
import util from '../../../util/date';
import { getTaskAssignList, assignTask, getExpertDataDictRelationList, getAreaDictionaryList } from '../../api/api';
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import Qs from 'qs'
export default {
	components: { Toolbar },
	data() {
		return {
            utils: '',
			dataPer:[],
			listLoading: false,
			//列表选中列
			sels: [],
			//增删改查按钮
			buttonList: [],
			yuanzhu: [],
			online1: [],
			online2: [],
            pages: {  //关于分页的obj
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
			//顶部筛选条件
            filters: {
                name: '',
                areaStr: '',
                taskStatus: null
            },
			addLoading: false,
            options:[
                {label:'同时期任务重叠冲突',value:105},
                {label:'特殊项目，无法胜任',value:106}
            ],
            selectItem: {
                localEngineer: {},
                assistanceEngineer: {},
                onlineEngineer1: {},
                onlineEngineer2: {},
            },
            assignTaskVisible: false,
            assignTaskLoading: false,
            viewsData: {
                viwesInformationType: 104
            },
            asssignData: {
                assistanceEngineer: {},
                onlineEngineer1: {},
                onlineEngineer2: {},
            },
            areaArr:[],
		};
	},
	methods: {
		// 筛选框清空，重新获取数据
		clearContent(){
			this.getListData()
		},
		//当前行发生变化时
		selectCurrentRow(val) {
            if(val){
                this.selectItem = val;
            }
		},
		//选项发生变化时
		selsChange: function (sels) {
			this.sels = sels;
		},
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getListData();
        },

        handleSizeChange(val){
            this.pages.pageSize = val
            this.getListData();
        },
		//获取数据权限列表
        getListData(){
            this.listLoading = true;
            let params = {
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            };
            if(this.filters.searchKey){
                params.searchKey = this.filters.searchKey;
            }
            if(this.filters.taskStatus){
                params.status = this.filters.taskStatus;
            }
            if(this.filters.areaStr){
                params.provinceCode = this.filters.areaStr;
            }
            getTaskAssignList(params).then((res) => {
                this.dataPer = res.data.response.data;
                this.listLoading = false;
                this.pages.dataCount = res.data.response.dataCount
                this.pages.pageIndex = res.data.response.pageIndex
                if(res.data.response.pageSize > 0){
                    this.pages.pageSize = res.data.response.pageSize
                }
            });
        },
        showHandleDialog(item) {
            this.selectItem = item;
            this.assignTaskVisible = true;
        },
        assignTask() {
            if(!this.selectItem.localEngineer.engineerId){
                this.$message.error("请选择待分配项目！");
                return;
            }
            this.assignTaskVisible = true;
        },
        getYuanzhuExpert(queryString, callBack) {
            let param = {
                pageSize: 999,
                expertType: 63,
                provinceCode: this.selectItem.provinceCode,
                keyWord: queryString,
            };
            getExpertDataDictRelationList(param).then((res) => {
                if (res.data.success) {
                    this.yuanzhu = res.data.response.data;
                    callBack(this.yuanzhu);
                }
            })
        },
        getOnline1Expert(queryString, callBack) {
            let param = {
                pageSize: 999,
                expertType: 63,
                keyWord: queryString,
            };
            getExpertDataDictRelationList(param).then((res) => {
                if (res.data.success) {
                    this.online1 = res.data.response.data;
                    callBack(this.online1);
                }
            })
        },
        getOnline2Expert(queryString, callBack) {
            let param = {
                pageSize: 999,
                expertType: 63,
                keyWord: queryString,
            };
            getExpertDataDictRelationList(param).then((res) => {
                if (res.data.success) {
                    this.online2 = res.data.response.data;
                    callBack(this.online2);
                }
            })
        },
        handleSelectAsistance(item) {
            console.log(item);
            this.asssignData.assistanceEngineer = {
                engineerName: item.userName,
                engineerId: item.userInfoId,
            };
        },
        handleSelectOnline1(item) {
            console.log(item);
            this.asssignData.onlineEngineer1 = {
                engineerName: item.userName,
                engineerId: item.userInfoId,
            };
        },
        handleSelectOnline2(item) {
            console.log(item);
            this.asssignData.onlineEngineer2 = {
                engineerName: item.userName,
                engineerId: item.userInfoId,
            };
        },
        assignTaskConfirm() {
            let params = {};
            if(this.selectItem.localEngineer.isApplyAssistance){
                if(!this.asssignData.assistanceEngineer){
                    this.$message.error("请选择省内援助专家！");
                    return;
                }else{
                    params.assistanceEngineer = {
                        taskId: this.selectItem.assistanceEngineer.taskId,
                        engineerId: this.asssignData.assistanceEngineer.engineerId,
                    };
                }
            }
            if(!this.selectItem.onlineEngineer1.isAssigned){
                if(!this.asssignData.onlineEngineer1){
                    this.$message.error("请选择图审专家1！");
                    return;
                }else{
                    params.onlineEngineer1 = {
                        taskId: this.selectItem.onlineEngineer1.taskId,
                        engineerId: this.asssignData.onlineEngineer1.engineerId,
                    };
                }
            }
            if(!this.selectItem.onlineEngineer2.isAssigned){
                if(!this.asssignData.onlineEngineer2){
                    this.$message.error("请选择图审专家2！");
                    return;
                }else{
                    params.onlineEngineer2 = {
                        taskId: this.selectItem.onlineEngineer2.taskId,
                        engineerId: this.asssignData.onlineEngineer2.engineerId,
                    };
                }
            }
            this.assignTaskLoading = true
            assignTask(params).then((res) => {
                this.assignTaskVisible = false;
                if(res.data.response){
                    this.$message({
                        message: '指派任务成功！',
                        type: 'success'
                    });
                    this.getListData();
                }else{
                    this.$message({
                        message: '指派任务失败！',
                        type: 'error'
                    });
                }
                this.assignTaskLoading = false
            });
        },
        getAreaData(){
            getAreaDictionaryList({ parentId: 1 }).then((res) => {
                this.areaArr = res.data.response;
            })
        },
		callFunction(item) {
            if(item.func == "getListData") {
                this.pages.pageIndex = 1
            }
			this[item.func].apply(this, item);
		},
        rowClass({columnIndex}){
            return 'padding:8px!important;'
        },
        // 表格数据分页条数累计
        indexMethod(index) {
            var currentPage = this.pages.pageIndex
            var pageSize = this.pages.pageSize
            return (index + 1) + (currentPage - 1) * pageSize
        },
	},
	mounted() {
        this.utils = util
		this.getListData();
        this.getAreaData();
		let routers = window.localStorage.router
		? JSON.parse(window.localStorage.router)
		: [];
		this.buttonList = getButtonList(this.$route.path, routers);
	}
};
</script>
<style lang="stylus" scoped>
.el-table>>>td, .el-table>>>th{
	padding: 8px!important;
}
.expertSelect{
    width: 500px;
}
</style>
